<section id="profile-creation">
	<div class="container">
	  	<div class="tab-profile">
		 	<header id="top-header" class="profile-head-widget">
				<div class="container">
		  			<div class="logo-header">
		  				<a href="">  <img src="https://resources.donorzilla.com/statics/images/logo.png" class="text-center"></a>
		  			</div>
				</div>
			</header>
			<div class="tab-stat-profile">
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center p-0 mt-3 mb-2">
						<div class="card carrdd px-0 pt-4 pb-0 mt-3 mb-3">
							<input type="hidden" id="longitude"/>
							<input type="hidden" id="latitude"/>
							<input type="hidden" id="address" />
							<input type="hidden" id="city" />
							<input type="hidden" id="state" />
							<input type="hidden" id="country" />
							<form [formGroup]="profileForm" (ngSubmit)="profileNew($event)" id="msform">
								<fieldset>
									<div class="form-card">
										<div class="row">
											<div class="col-12">
									  			<div class="stp-btm">
													<h2 class="steps">1 of 7</h2>
									  			</div>
											</div>
										</div>
										<div role="tabpanel" id="Name" class="tabpanel tbpnl1">
											<h3>1. Who You Are?</h3>
											<select class="form-input" (change)="onChangeSelect($event)" formControlName="role_type" id="role_type">
                                    			<option  value="" selected>Select</option>
												<option class="select" *ngFor="let role of roleList; let i = index" [value]="role.id">
													{{role.name}}
												</option>
                                			</select>
		  								</div>
									</div> 
									<input type="button" data-name="step-1" name="next" class="next action-button act-btn-n" value="Next" />
								</fieldset>
					   			<fieldset>
									<div class="form-card">
										<div class="row">
											<div class="col-12">
									  			<div class="stp-btm">
													<h2 class="steps">2 of 7</h2>
									  			</div>
											</div>
										</div>
										<div role="tabpanel" class="tabpanel">
											<h3>2. Name of {{title}}?</h3>
											<input type="text" id="title" formControlName="name" class="form-input" placeholder="Type here...">
		  								</div>
									</div> 
									<input type="button" id="nextbtnClick" data-name="step-2" name="next" class="next action-button act-btn-n" value="Next" />
									<input type="button" name="previous" class="previous action-button-previous act-btn-y" value="Previous" />
								</fieldset>
								<fieldset>
									<div class="form-card">
										<div class="row">
									   		<div class="col-12">
									  			<div class="stp-btm">
													<h2 class="steps">3 of 7</h2>
									  			</div>
											</div>
										</div> 
										<div role="tabpanel" class="tabpanel ">
											<h3>3. Your Address?</h3>
											<input type="text" formControlName="myaddress"  class="form-input" placeholder="Type here..." id="myAddress"><br>
											<div id="map"></div>
		  								</div>
									</div>
							 		<input type="button" data-name="step-3" name="next" class="next action-button act-btn-n" value="Next" />
									<input type="button" name="previous" class="previous action-button-previous act-btn-y" value="Previous" />
								</fieldset>
								<fieldset>
									<div class="form-card">
										<div class="row">
									 		<div class="col-12">
									  			<div class="stp-btm">
													<h2 class="steps">4 of 7</h2>
									  			</div>
											</div>
										</div>
										<div role="tabpanel" class="tabpanel tbpnl1">
											<h3>4. Your Email address?</h3>
											<input type="text" minlength="5" maxlength="50" formControlName="email"  class="form-input" placeholder="Type here..." id="email">
		  								</div>
									</div>
							  		<input type="button" data-name="step-4" name="next" class="next action-button act-btn-n" value="Next" /> <input type="button" name="previous" class="previous action-button-previous act-btn-y" value="Previous" />
								</fieldset>
								<fieldset>
									<div class="form-card">
										<div class="row">
											<div class="col-12">
									  			<div class="stp-btm">
													<h2 class="steps">5 of 7</h2>
									  			</div>
											</div>
										</div> 
								   		<div role="tabpanel" class="tabpanel ">
											<h3>5. Your Phone Number?</h3>
											<input type="text" minlength="10" maxlength="10" id="phone" onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="phone" class="form-input" placeholder="Type here...">
		  								</div>
			 							<div role="tabpanel" class="tabpanel ">
											<h3>Alternate Phone Number?</h3>
											<input type="text" minlength="10" maxlength="10" id="e_phone" onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="e_phone" class="form-input" placeholder="Type here...">
		 		 						</div>
									</div>
							  		<input type="button" data-name="step-5" name="next" class="next action-button act-btn-n" value="Next" /> <input type="button" name="previous" class="previous action-button-previous act-btn-y" value="Previous" />
								</fieldset>
						 		<fieldset>
									<div class="form-card">
										<div class="row">
									 		<div class="col-12">
									 	 		<div class="stp-btm">
													<h2 class="steps">6 of 7</h2>
									  			</div>
											</div>
										</div> 
								 		<div role="tabpanel" class="tabpanel ">
											<h3>6. Your Contact Person Name?</h3>
											<input type="text" minlength="3" maxlength="30" id="c_name" formControlName="c_name"  class="form-input" placeholder="Type here...">
		  								</div>
		  								<div class="row">
											<div class="col-md-6">
			   									<div role="tabpanel" class="tabpanel ">
													<h3>Your Contact Person Email?</h3>
													<input type="text" minlength="5" maxlength="30" id="c_email" formControlName="c_email"  class="form-input" placeholder="Type here...">
		 	 									</div>
											</div>
			 								<div class="col-md-6">
			   									<div role="tabpanel" class="tabpanel ">
													<h3>Your Contact Person Phone?</h3>
													<input type="text" minlength="10" maxlength="10" id="c_phone" onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="c_phone"  class="form-input" placeholder="Type here...">
		  										</div>
											</div>
		  								</div>
									</div>
							   		<input type="button" data-name="step-6" name="next" class="next action-button act-btn-n" value="Next" /> <input type="button" name="previous" class="previous action-button-previous act-btn-y" value="Previous" />
								</fieldset>
						 		<fieldset>
									<div class="form-card">
										<div class="row">
									 		<div class="col-12">
									  			<div class="stp-btm">
													<h2 class="steps">7 of 7</h2>
									  			</div>
											</div>
										</div> 
									   	<div role="tabpanel" id="Name" class="tabpanel ">
											<h3>7. Let us know more about yourself.</h3>
											<textarea formControlName="about" class="form-input " maxlength="300" placeholder="Type here..."></textarea>
		  								</div>
									</div> 
							 		<input type="submit" data-name="step-7" name="next" class=" action-button act-btn-n" value="Submit" /> 
							 		<input type="button" name="previous" class="previous action-button-previous act-btn-y" value="Previous" />
								</fieldset>
						   		<div class="progress-with-step">
									<ul id="progressbar">
										<li class="active" id="account"><strong>.</strong></li>
										<li id="personal"><strong>.</strong></li>
										<li id="payment"><strong>.</strong></li>
										<li id="confirm"><strong>.</strong></li>
										<li id="paymentt"><strong>.</strong></li>
										<li id="paymet"><strong>.</strong></li>
										<li id="confirmm"><strong>.</strong></li>
									</ul>
								</div>
							</form>
						</div>
					</div>
				</div>	
	  		</div>
	  	</div>
	</div>
</section>
<footer class="footer-bottom">
    <div>Donorzilla &copy;{{currentYear}}, All Rights Reserved.</div>
</footer>