<main>
	<section>
		<div class="rad-body-wrapper bg-chnage">
			<div class="container-fluid">
				<div class="white-bgg">
					<div class="uploadimg">
						<form [formGroup]="addBusinessForm" (ngSubmit)="addBusinessImages($event)" class="form" encType="multipart/form-data">
							<div class="row">
								<div class="col-lg-5 col-md-12 col-xs-12">
									<div class="business-gallery-1 spclick">
										<input #imageInput type="file" id="file-input" class="hidden-file" accept="image/*" (change)="processFile(imageInput)" multiple="">
									</div>
									<button type="submit" value="Submit" class="gallerybtn">Upload</button>
								</div>
								<div class="col-lg-7 col-md-12 col-xs-12">
									<div class="business-gallery-2">
										<div class="row">
											<div class="col-lg-4 col-md-12 col-xs-12" *ngFor='let url of images'>
												<div class="business-gallery-rightside">
													<img [src]="url" class="imgprev">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div class="row">
						<div class="col-lg-12 col-md-12 col-xs-12">
							<div class="business-gallery-3">
								<h2 class="head-recent">Recent Uploaded Images</h2>
								<div class="row">
									<div class="col-lg-2 col-md-12 col-xs-12" *ngFor='let image of imageList'>
										<div class="business-gallery-bottom-side">
											<img [src]="image.image" class="imgprev">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</main>