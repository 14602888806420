import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { LayoutModule } from "./layout/layout.module";
import { AppRoutingModule } from "./app-routing.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { JwPaginationModule } from "jw-angular-pagination";

import { AuthGuard } from "./auth.guard";

import { HomeModule } from "./home/home.module";

import { AboutUsModule } from "./about-us/about-us.module";

import { ContactUsModule } from "./contact-us/contact-us.module";

import { FaqModule } from "./faq/faq.module";

import { LoginModule } from "./login/login.module";

import { RegisterModule } from "./register/register.module";

import { LogoutModule } from "./logout/logout.module";

import { DashboardModule } from "./dashboard/dashboard.module";

import { DonorDashboardModule } from "./donor-dashboard/donor-dashboard.module";

import { AppointmentSettingsModule } from "./appointment-settings/appointment-settings.module";

import { AppointmentDetailsModule } from "./appointment-details/appointment-details.module";

import { GalleryModule } from "./gallery/gallery.module";

import { DoctorListModule } from "./doctor-list/doctor-list.module";

import { ProfileModule } from "./profile/profile.module";

import { ProfileNewModule } from "./profile-new/profile-new.module";

import { ThankyouModule } from "./thankyou/thankyou.module";

import { DeleteAccountModule } from "./delete-account/delete-account.module";

import { DeleteAccountThankyouModule } from "./delete-account-thankyou/delete-account-thankyou.module";

import { PrivacyPolicyModule } from "./privacy-policy/privacy-policy.module";

import { TermsConditionModule } from "./terms-condition/terms-condition.module";

import { BusinessListingModule } from "./business-listing/business-listing.module";

import { DonorListingModule } from "./donor-listing/donor-listing.module";

import { ArticleDetailsModule } from "./article-details/article-details.module";

import { PublicProfileModule } from "./public-profile/public-profile.module";

import { ArticleListModule } from "./article-list/article-list.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    JwPaginationModule,
    HttpClientModule,
    RouterModule.forRoot([]),
    AppRoutingModule,
    FlexLayoutModule,
    LayoutModule,
    HomeModule,
    AboutUsModule,
    ContactUsModule,
    FaqModule,
    LoginModule,
    RegisterModule,
    LogoutModule,
    DashboardModule,
    DonorDashboardModule,
    GalleryModule,
    DoctorListModule,
    ArticleDetailsModule,
    AppointmentSettingsModule,
    AppointmentDetailsModule,
    ProfileModule,
    ProfileNewModule,
    GooglePlaceModule,
    DeleteAccountModule,
    DeleteAccountThankyouModule,
    ThankyouModule,
    PrivacyPolicyModule,
    PublicProfileModule,
    TermsConditionModule,
    BusinessListingModule,
    ArticleListModule,
    DonorListingModule
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
