<main>
	<section>
		<div class="rad-body-wrapper bg-chnage">
			<div class="container-fluid">
				<div class="white-bgg">
					<div class="row">
						<div class="col-lg-12 col-md-12 col-xs-12">
							<div class="business-gallery-3">
								<div class="row">
									<div class="col-lg-12 col-md-12 col-xs-12">
										<button type="button" (click)="openDoctorAdd(addDoctorContent)" class="gallerybtn"><i class="fa fa-plus"></i> Add new</button>
									</div>
									<div class="col-lg-3 col-md-12 col-xs-12" style="margin-top: 20px;" *ngFor="let doctor of doctorList;let i = index">
										<div class="business-gallery-bottom-side">
											<img [src]="doctor.image" class="imgprev">
											<button type="button" (click)="openDoctorEdit(updateDoctorContent, doctor.id)" class="doctor-edit-btn"><i class="fa fa-edit"></i> Edit</button>
											<h4 class="doctor-mb-0">{{doctor.name}}</h4>
											<p class="doctor-mb-0" style="margin-top:10px;">{{doctor.department_name}}</p>
											<p class="doctor-mb-0">{{doctor.bio}}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</main>
<ng-template #addDoctorContent let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="mx-5">Add Doctor</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
			<i class="ki-duotone ki-cross fs-1">
				<span class="path1"></span>
				<span class="path2"></span>
			</i>
		</div>
	</div>
	<div class="modal-body scroll-y mx-xl-15">
		<div class="login-container">
			<div class="login-form">
				<div class="login-form-inner" style="max-width: 100%;">
					<form [formGroup]="addDoctorForm" (ngSubmit)="addDoctor($event)" enctype='multipart/form-data'>
						<div class="login-form-group">
							<label for="name">Name <span class="required-star">*</span></label>
							<input type="text" class="input-modal" placeholder="Name" formControlName="name" autocomplete="off">
						</div>
						<div class="login-form-group">
							<label for="e_phone">Image <span class="required-star">*</span></label>
							<div class="business-gallery-1 spclick">
								<input type="file" #imageInput id="file-input" (change)="processFile(imageInput)" accept="image/*">
							</div>
						</div>
						<div class="login-form-group">
							<label for="c_phone">Department Name <span class="required-star">*</span></label>
							<select class="form-control mb-15" formControlName="department_id">
								<option value="" selected="selected">Select Department Name</option>
								<option value="{{department.id}}" *ngFor="let department of departmentList;let i = index">{{department.name}}</option>
							</select>
						</div>
						<div class="login-form-group">
							<label for="c_email">About yourself <span class="required-star">*</span></label>
							<input type="text" class="input-modal" placeholder="About yourself" formControlName="bio" autocomplete="off">
						</div>
						<div *ngIf="successMessage">
							<div class="alert alert-success" role="alert">
								{{successMessage}}
							</div>
						</div>
						<div *ngIf="errorMessage">
							<div class="alert alert-danger" role="alert">
								{{errorMessage}}
							</div>
						</div>
						<button type="submit" class="btn bg-gradient-primary wd-25 submit-btn-gradient">Submit</button>
						<button type="button" (click)="modal.dismiss('Cross click')" class="btn bg-gradient-primary wd-25 cancel-btn-modal">Cancel</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #updateDoctorContent let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="mx-5">Update Doctor</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
			<i class="ki-duotone ki-cross fs-1">
				<span class="path1"></span>
				<span class="path2"></span>
			</i>
		</div>
	</div>
	<div class="modal-body scroll-y mx-xl-15">
		<div class="login-container">
			<div class="login-form">
				<div class="login-form-inner" style="max-width: 100%;">
					<form [formGroup]="updateDoctorForm" (ngSubmit)="updateDoctor($event)" enctype='multipart/form-data'>
						<div class="login-form-group">
							<label for="name">Name <span class="required-star">*</span></label>
							<input type="text" class="input-modal" placeholder="Name" formControlName="name" autocomplete="off">
						</div>
						<div class="login-form-group">
							<label for="e_phone">Image <span class="required-star">*</span></label>
							<div class="business-gallery-1 spclick">
								<input type="file" #imageInput id="file-input" (change)="processFile(imageInput)" accept="image/*">
							</div>
						</div>
						<div class="login-form-group">
							<label for="c_phone">Department Name <span class="required-star">*</span></label>
							<select class="form-control mb-15" formControlName="department_id">
								<option value="" selected="selected">Select Department Name</option>
								<option value="{{department.id}}" *ngFor="let department of departmentList;let i = index">{{department.name}}</option>
							</select>
						</div>
						<div class="login-form-group">
							<label for="c_email">About yourself <span class="required-star">*</span></label>
							<input type="text" class="input-modal" placeholder="About yourself" formControlName="bio" autocomplete="off">
						</div>
						<div *ngIf="successMessage">
							<div class="alert alert-success" role="alert">
								{{successMessage}}
							</div>
						</div>
						<div *ngIf="errorMessage">
							<div class="alert alert-danger" role="alert">
								{{errorMessage}}
							</div>
						</div>
						<button type="submit" class="btn bg-gradient-primary wd-25 submit-btn-gradient">Update</button>
						<button type="button" (click)="modal.dismiss('Cross click')" class="btn bg-gradient-primary wd-25 cancel-btn-modal">Cancel</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>