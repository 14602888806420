<aside>
    <nav class="rad-sidebar">
        <ul>
            <li *ngIf="role_type != 2" [ngClass]="currentRoute == '/dashboard' ? 'active' : ''">
                <a *ngIf="!map_id" href="dashboard" class="inbox">
                    <i aria-hidden="true" class="fa fa-home"></i>
                    <span class="rad-sidebar-item">Home</span>
                </a>
                <a *ngIf="map_id" href="dashboard?b_id={{map_id}}" class="inbox">
                    <i aria-hidden="true" class="fa fa-home"></i>
                    <span class="rad-sidebar-item">Home</span>
                </a>
            </li>
            <li *ngIf="role_type == 2" [ngClass]="currentRoute == '/donor-dashboard' ? 'active' : ''">
                <a href="donor-dashboard" class="inbox">
                    <i aria-hidden="true" class="fa fa-home"></i>
                    <span class="rad-sidebar-item">Home</span>
                </a>
            </li>
            <li [ngClass]="currentRoute == '/gallery' ? 'active' : ''">
                <a *ngIf="!map_id" href="gallery">
                    <i class="fa fa-image"></i>
                    <span class="rad-sidebar-item">Gallery</span>
                </a>
                <a *ngIf="map_id" href="gallery?b_id={{map_id}}">
                    <i class="fa fa-image"></i>
                    <span class="rad-sidebar-item">Gallery</span>
                </a>
            </li>
            <li [ngClass]="currentRoute == '/appointment-settings' ? 'active' : ''">
                <a *ngIf="!map_id" href="appointment-settings" class="snooz">
                    <i class="fa fa-calendar"></i>
                    <span class="rad-sidebar-item">Appointments</span>
                </a>
                <a *ngIf="map_id" href="appointment-settings?b_id={{map_id}}" class="snooz">
                    <i class="fa fa-calendar"></i>
                    <span class="rad-sidebar-item">Appointments</span>
                </a>
            </li>
            <li [ngClass]="currentRoute == '/doctor-list' ? 'active' : ''">
                <a *ngIf="!map_id" href="doctor-list">
                    <i class="fa fa-user-md"></i>
                    <span class="rad-sidebar-item">Doctor List</span>
                </a>
                <a *ngIf="map_id" href="doctor-list?b_id={{map_id}}">
                    <i class="fa fa-user-md"></i>
                    <span class="rad-sidebar-item">Doctor List</span>
                </a>
            </li>
            <li>
                <a href="faq" target="_blank" class="done">
                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                    <span class="rad-sidebar-item">Help</span>
                </a>
            </li>
        </ul>
        <div class="btn-dashboard" *ngIf="detailedList">
            <a href="profile_new/{{detailedList.details.id}}">Add New Branch</a>
        </div>
    </nav>
</aside>