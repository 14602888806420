<header id="top-header">
    <div class="container">
        <div class="logo-header">
            <img src="https://resources.donorzilla.com/statics/images/logo.png" class="text-center">
        </div>
    </div>
</header>
<div class="login-container">
    <div class="onboarding">
        <div class="images-signin">
            <img src="https://resources.donorzilla.com/statics/images/mask.png">
        </div>
    </div>
    <div class="login-form">
        <div class="login-form-inner" *ngIf="!otpSent">
            <h1 class="text-center">Sign Up</h1>
            <p class="body-text text-center">Sign Up with phone</p>
            <form [formGroup]="registerForm" (ngSubmit)="register($event)" *ngIf="!otpSent">
                <div class="login-form-group logi-1">
                    <label for="email">First Name <span class="required-star">*</span></label>
                    <input type="text" maxlength="20" placeholder="First Name" formControlName="first_name">
                </div>
                <div class="login-form-group logi-1">
                    <label for="pwd">Last Name <span class="required-star">*</span></label>
                    <input type="text" maxlength="15" placeholder="Last Name" formControlName="last_name" autocomplete="off">
                </div>
                <div class="login-form-group">
                    <label for="pwd">Phone Number <span class="required-star">*</span></label>
                    <input type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" minlength="10" maxlength="10" placeholder="Phone" formControlName="phone" autocomplete="off">
                </div>
                <div class="custom-check">
                    <input autocomplete="off" type="checkbox" checked id="remember"><label for="remember">Remember me</label>
                </div>
                <button type="submit" class="rounded-button login-cta">Sign Up</button>
                <div class="register-div">Have an Account? <a href="login" class="link create-account">Login</a></div>
            </form>
        </div>
        <div class="login-form-inner" *ngIf="otpSent">
            <h1 class="text-center">Verify OTP</h1>
            <p class="body-text text-center">Enter the OTP and proceed</p>
            <form [formGroup]="otpForm" (ngSubmit)="verifyOtp($event)" >
                <div class="login-form-group log-otp-frm-gro">
                    <label for="pwd">Phone Number <span class="required-star">*</span></label>
                    <div class="phone-edit">
                        <p id="pwd">{{requestPhone}}</p>
                        <a href="login"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                        <input type="hidden" formControlName="verifyPhone" autocomplete="off">
                    </div>
                </div>
                <div class="otp-fld-new">
                    <p>Enter OTP</p>
                    <div class="otp-field ">
                        <input type="number" placeholder="-" formControlName="otp1" autocomplete="off">
                        <input type="number" placeholder="-" formControlName="otp2" autocomplete="off">
                        <input type="number" placeholder="-" formControlName="otp3" autocomplete="off">
                        <input type="number" placeholder="-" formControlName="otp4" autocomplete="off">
                    </div>
                </div>
                <div class="time-left-1">
                    <div>Time left : {{display }} </div>
                </div>
                <button type="submit" id="verifyOtpBtn" class="rounded-button login-cta">Submit</button>
            </form>
            <p class="resend text-muted mb-0" *ngIf="resendEnable">
                Didn't receive code? <a href="javascript:;" (click)="resendOtp($event, requestPhone)">Request again</a>
            </p>
        </div>
    </div>
</div>
<div class="log-sin-ot">
    <img src="https://resources.donorzilla.com/statics/images/sign-right.png">
</div>
<footer class="footer-bottom">
    <div>Donorzilla &copy;{{currentYear}}, All Rights Reserved.</div>
</footer>