import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { BusinessMasterService } from "../../services/businessMaster.service";
import { BusinessMaster } from '../../models/businessMaster';

@Component({
  selector: "app-business-sidebar",
  templateUrl: "./business-sidebar.component.html",
  styleUrls: [
	'./business-sidebar.component.css'
]
})

export class BusinessSidebarComponent implements OnInit {

	map_id: any;
	user_id = 0;
	role_type = 0;
	b_id: any;
	detailedList: any;
	currentRoute = '';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private businessMasterService: BusinessMasterService
	) {}

  	ngOnInit() { 
		let currentRoute1 = this.router.url;
		let routeArr = currentRoute1.split("?");
		this.currentRoute = routeArr[0];
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
			this.role_type = userArray.data.role_type;
			
			this.b_id = this.route.snapshot.queryParams.b_id;
			if(this.b_id){
				this.getRoleDetailsById(this.b_id);
			} else{
				this.getRoleDetails(this.user_id);
			}
		}
	}

	getRoleDetailsById(b_id: any): void {
		this.businessMasterService.getBusinessById({ b_id } as BusinessMaster)
			.subscribe(
				data => {
					this.detailedList = data.data;
					this.map_id = data.data.map_id;
				}
			);
	}

	getRoleDetails(user_id: any): void {
		this.businessMasterService.getBusniessMasterByUserId({ user_id } as BusinessMaster)
		.subscribe(
			data => {
				this.detailedList = data.data;
				this.map_id = data.data.map_id;
			}
		);
	}
}
