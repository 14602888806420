<main>
	<section>
	  	<div class="rad-body-wrapper">
			<div class="container-fluid">
				<div class="white-bgg">
					<nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true" *ngIf="detailedList">
						<div class="container-fluid py-1 px-3 navb">
							  <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
								<div class="ms-md-auto pe-md-3 d-flex align-items-center">
									  <div class="help">
										<div class="form-check form-switch ps-0 switch-chk">
											<input class="form-check-input ms-auto switch-chk-input" [checked] = "detailedList.details.is_appointment" type="checkbox" id="flexSwitchCheckDefault3" (change)="updateAppointmentSetting($event)">
											<label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault3" *ngIf="!detailedList.details.is_appointment">Enable</label>
											<label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault3" *ngIf="detailedList.details.is_appointment">Disable</label>
										</div>
									  </div>
								</div>
							  </div>
						</div>
					</nav>
					<div class="row" *ngIf="detailedList">
			  			<div class="col-lg-12 col-md-12 col-xs-12" *ngIf="detailedList.details.is_appointment == 1">
							<div class="business-info">
								<form [formGroup]="aptSettingForm" (ngSubmit)="createAptSetting($event)" class="appointment-form">
								 	<div class="row">
										<div class="col-lg-6 col-md-6 col-xs-12">
											<div class="login-form-group welcomeafter">
												<label class="newpl">Invitees can schedule...</label>
												<select formControlName="slot_duration" (change)="checkSlot($event)">
													<option value="">Days</option>
													<option value="15">15</option>
													<option value="30">30</option>
													<option value="60">60</option>
												</select>
												<label class="newplbott">into the futute</label>
											</div>
										</div>
										<div class="col-lg-6 col-md-6 col-xs-12">
											<div class="login-form-group welcomeafter">
												<label class="newpl">Time Duration (In Minutes)</label>
												<select formControlName="slot_duration">
													<option value="">--select slot time--</option>
													<option value="15">15</option>
													<option value="30">30</option>
													<option value="60">60</option>
												</select>
												<label class="newplbott">&nbsp;</label>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-lg-1 col-md-6 col-xs-12"></div>
										<div class="col-lg-10 col-md-6 col-xs-12">
											<div class="login-form-group workinghour" *ngIf="is_slot_time">
												<label class="newpl">Working Hours</label>
												<table class="table-wi">
													<tr  class="time-tab-row">
														<td class="tabday">
															<input type="checkbox" (change)="dayEvent($event, 'SUN')">
															<label class="">Sun</label>
														</td>
														<td class="time-tabs" *ngIf="sunUTd">
															<label class="unavil-1">Unavailable</label>
														</td>
														<td class="time-tab" *ngIf="sunTd">
															<tr>
																<td class="tidue">
																	<input (change)="timeToChange($event, 'SUN')" id="SUNTo" type="time" value="09:00">
																	<span> - </span>
																	<input (change)="timeFromChange($event, 'SUN')" type="time" id="SUNFrom" value="17:00">
																	<i class="fa fa-times nne"></i>
																</td>
																
															</tr>
														</td>
														<td class="plusright">
															<i class="fa fa-plus"></i>
														</td>
													</tr>
													<tr  class="time-tab-row">
														<td class="tabday">
															<input type="checkbox" (change)="dayEvent($event, 'MON')">
															<label class="">Mon</label>
														</td>
														<td class="time-tabs" *ngIf="monUTd">
															<label class="unavil-1">Unavailable</label>
														</td>
														<td class="time-tab" *ngIf="monTd">
															<tr>
																<td class="tidue">
																	<input (change)="timeToChange($event, 'MON')" id="MONTo" type="time" value="09:00">
																	<span> - </span>
																	<input (change)="timeFromChange($event, 'MON')" type="time" id="MONFrom" value="17:00">
																	<i class="fa fa-times nne"></i>
																</td>
																
															</tr>
														</td>
														<td class="plusright">
															<i class="fa fa-plus"></i>
														</td>
													</tr>
													<tr  class="time-tab-row">
														<td class="tabday">
															<input type="checkbox" (change)="dayEvent($event, 'TUE')">
															<label class="">Tue</label>
														</td>
														<td class="time-tabs" *ngIf="tueUTd">
															<label class="unavil-1">Unavailable</label>
														</td>
														<td class="time-tab" *ngIf="tueTd">
															<tr>
																<td class="tidue">
																	<input (change)="timeToChange($event, 'TUE')" id="TUETo" type="time" value="09:00">
																	<span> - </span>
																	<input (change)="timeFromChange($event, 'TUE')" type="time" id="TUEFrom" value="17:00">
																	<i class="fa fa-times nne"></i>
																</td>
																
															</tr>
														</td>
														<td class="plusright">
															<i class="fa fa-plus"></i>
														</td>
													</tr>
													<tr  class="time-tab-row">
														<td class="tabday">
															<input type="checkbox" (change)="dayEvent($event, 'WED')">
															<label class="">Wed</label>
														</td>
														<td class="time-tabs" *ngIf="wedUTd">
															<label class="unavil-1">Unavailable</label>
														</td>
														<td class="time-tab" *ngIf="wedTd">
															<tr>
																<td class="tidue">
																	<input (change)="timeToChange($event, 'WED')" id="WEDTo" type="time" value="09:00">
																	<span> - </span>
																	<input (change)="timeFromChange($event, 'WED')" type="time" id="WEDFrom" value="17:00">
																	<i class="fa fa-times nne"></i>
																</td>
																
															</tr>
														</td>
														<td class="plusright">
															<i class="fa fa-plus"></i>
														</td>
													</tr>
													<tr  class="time-tab-row">
														<td class="tabday">
															<input type="checkbox" (change)="dayEvent($event, 'THU')">
															<label class="">Thu</label>
														</td>
														<td class="time-tabs" *ngIf="thuUTd">
															<label class="unavil-1">Unavailable</label>
														</td>
														<td class="time-tab" *ngIf="thuTd">
															<tr>
																<td class="tidue">
																	<input (change)="timeToChange($event, 'THU')" id="THUTo" type="time" value="09:00">
																	<span> - </span>
																	<input (change)="timeFromChange($event, 'THU')" type="time" id="THUFrom" value="17:00">
																	<i class="fa fa-times nne"></i>
																</td>
																
															</tr>
														</td>
														<td class="plusright">
															<i class="fa fa-plus"></i>
														</td>
													</tr>
													<tr  class="time-tab-row">
														<td class="tabday">
															<input type="checkbox" (change)="dayEvent($event, 'FRI')">
															<label class="">Fri</label>
														</td>
														<td class="time-tabs" *ngIf="friUTd">
															<label class="unavil-1">Unavailable</label>
														</td>
														<td class="time-tab" *ngIf="friTd">
															<tr>
																<td class="tidue">
																	<input (change)="timeToChange($event, 'FRI')" id="FRITo" type="time" value="09:00">
																	<span> - </span>
																	<input (change)="timeFromChange($event, 'FRI')" type="time" id="FRIFrom" value="17:00">
																	<i class="fa fa-times nne"></i>
																</td>
																
															</tr>
														</td>
														<td class="plusright">
															<i class="fa fa-plus"></i>
														</td>
													</tr>
													<tr  class="time-tab-row">
														<td class="tabday">
															<input type="checkbox" (change)="dayEvent($event, 'SAT')">
															<label class="">Sat</label>
														</td>
														<td class="time-tabs" *ngIf="satUTd">
															<label class="unavil-1">Unavailable</label>
														</td>
														<td class="time-tab" *ngIf="satTd">
															<tr>
																<td class="tidue">
																	<input (change)="timeToChange($event, 'SAT')" id="SATTo" type="time" value="09:00">
																	<span> - </span>
																	<input (change)="timeFromChange($event, 'SAT')" type="time" id="SATFrom" value="17:00">
																	<i class="fa fa-times nne"></i>
																</td>
																
															</tr>
														</td>
														<td class="plusright">
															<i class="fa fa-plus"></i>
														</td>
													</tr>
												</table>
												<div class="butsbt">
												<button type="submit" class=" text-box-butnn">Submit</button></div>
											</div>
										</div>
									</div>
								</form>
							</div>
			  			</div>
					</div>
					<div class="container-fluid px-2 px-md-4" *ngIf="detailedList">
						<img *ngIf="detailedList.details.is_appointment == 0" src="https://resources.donorzilla.com/statics/images/loading.png" alt="profile_image" style="width: 80% !important;margin-left: 10%;height: 500px; object-fit: contain;" class="w-100 border-radius-lg shadow-sm">
					</div>
				</div>
			</div>
	  	</div>
	</section>
</main>