import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BusinessMaster } from '../../models/businessMaster';
import { Notification } from '../../models/notification';

import { BusinessMasterService } from "../../services/businessMaster.service";
import { NotificationService } from "../../services/notification.service";

@Component({
	selector: 'app-business-header',
	templateUrl: './business-header.component.html',
	styleUrls: [
		'./business-header.component.css'
	]
})
export class BusinessHeaderComponent implements OnInit {

	b_id: any;
	user_id = 0;
	role_type = 0;
	name = "";
	notificationCounter = 0;

	businessList: any;
	notificationList: any;

	errorMessage = "";
	successMessage = "";
  	constructor(
		private businessMasterService: BusinessMasterService,
		private route: ActivatedRoute,
		private notificationService: NotificationService
	) {}

  	ngOnInit() {
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
			this.role_type = userArray.data.role_type;
			this.name = userArray.data.name;
			this.getAllBusiness(this.user_id);
			this.getNotification(this.user_id);
			this.getNotificationCounter(this.user_id);
			this.b_id = this.route.snapshot.queryParams.b_id;

		}
	}
	
	getAllBusiness(user_id: any): void {
		this.businessMasterService.getAllBusinessByUserId({ user_id } as BusinessMaster)
		.subscribe(
			data => {
				this.businessList = data.data;
			}
		);
	}

	getNotification(user_id: any): void {
		this.notificationService.getNotificationByUserId({ user_id } as Notification)
		.subscribe(
			data => {
				this.notificationList = data.data;
			}
		);
	}

	getNotificationCounter(user_id: any): void {
		this.notificationService.getNotificationCounterByUserId({ user_id } as Notification)
		.subscribe(
			data => {
				this.notificationCounter = data.data.total;
			}
		);
	}

	updateNotification(): void {
		let user_id = this.user_id;
		this.notificationService.updateNotificationByUserId({ user_id } as Notification)
		.subscribe(
			data => {
				if (data.status == 200) {
					const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
				}
			}
		);
	}
	
	onChange(business_id: any): void {
		window.location.href = 'dashboard?b_id='+business_id.target.value;
	}

}
