<header>
    <div class="header_bottom">
        <div class="container">
            <div class="row align-items-center position-relative">
                <div class="col-xl-2 col-lg-2 col-md-4 col-6">
                    <div class="header_logo">
                        <a href=""><img src="https://resources.donorzilla.com/statics/images/logo-website.png" alt="images not founds" class="img-fluid"></a>
                    </div>
                </div>
                <div class="col-xl-10 col-lg-10 d-none d-xxl-block d-xl-block">
                    <ul class="main_menu">
                        <li class="position-relative"><a href="">Home</a></li>
                        <li><a href="donor">Blood Donors</a></li>
                        <li><a href="business">Business Listings</a></li>
                        <li><a href="articles">Articles</a></li>
                        <li><a href="contact-us">Contact Us</a></li>
                        <li><a style="color: #ff0000;" href="login">Login/</a><a style="color: #ff0000;" href="register">Register</a></li>
                    </ul>
                </div>
                <div class="col-lg-10 col-md-8 col-6 d-block d-xxl-none d-xl-none">
                    <div class="d-flex align-items-center gap-2 justify-content-end">
                        <div class="mobile_menu">
                            <button class="header_toggle_btn bg-transparent border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-mobile" aria-label="Menu" aria-labelledby="mobilemenudiv" title="Menu">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>