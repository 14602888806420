<main>
	<section>
	  	<div class="rad-body-wrapper">
			<div class="container-fluid">
				<div class="white-bg">
					<div class="row mt-4">
						<h3>Find & Book Appointment</h3>
						<div class="col-md-2 col-sm-6 col-6">
						  <div class="card">
							<div class="card-body text-center">
								<img alt="Image placeholder" class="donor-avatar rounded-circle" src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg">
							  <h6 class="mb-0 font-weight-bolder">Find Donors</h6>
							</div>
						  </div>
						</div>
						<div class="col-md-2 col-sm-6 col-6">
							<div class="card">
							  <div class="card-body text-center">
								  <img alt="Image placeholder" class="donor-avatar rounded-circle" src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg">
								<h6 class="mb-0 font-weight-bolder">Request Blood</h6>
							  </div>
							</div>
						  </div>
						  <div class="col-md-2 col-sm-6 col-6">
							<div class="card">
							  <div class="card-body text-center">
								  <img alt="Image placeholder" class="donor-avatar rounded-circle" src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg">
								<h6 class="mb-0 font-weight-bolder">Blood Bank</h6>
							  </div>
							</div>
						  </div>
						  <div class="col-md-2 col-sm-6 col-6">
							<div class="card">
							  <div class="card-body text-center">
								  <img alt="Image placeholder" class="donor-avatar rounded-circle" src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg">
								<h6 class="mb-0 font-weight-bolder">Hospitals</h6>
							  </div>
							</div>
						  </div>
						  <div class="col-md-2 col-sm-6 col-6">
							<div class="card">
							  <div class="card-body text-center">
								  <img alt="Image placeholder" class="donor-avatar rounded-circle" src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg">
								<h6 class="mb-0 font-weight-bolder">Path Labs</h6>
							  </div>
							</div>
						  </div>
						  <div class="col-md-2 col-sm-6 col-6">
							<div class="card">
							  <div class="card-body text-center">
								  <img alt="Image placeholder" class="donor-avatar rounded-circle" src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg">
								<h6 class="mb-0 font-weight-bolder">Doctors</h6>
							  </div>
							</div>
						  </div>
					  </div>
				</div>
				<div class="white-bg">
					<div class="row mt-lg-4 mt-2">
						<h3>Recent Requests</h3>
						<div class="input-group input-group-outline" style="margin-bottom: 30px;">
							<input type="text" class="form-control" placeholder="Search...">
							<button type="button" class="gallerybtn">Search</button>
						</div>
						<div class="col-lg-4 col-md-6 mb-4">
						  <div class="card">
							<div class="card-body p-3">
							  <div class="d-flex mt-n2">
								<div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
								  <img src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg" alt="slack_logo">
								</div>
								<div class="ms-3 my-auto">
								  <h6 class="mb-0">Slack Bot</h6>
								</div>
								<div class="ms-auto">
								  <div class="dropdown">
									<button class="btn btn-link text-secondary ps-0 pe-2" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									  <i class="fa fa-ellipsis-v text-lg"></i>
									</button>
									<div class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3" aria-labelledby="navbarDropdownMenuLink">
									  <a class="dropdown-item" href="javascript:;">Action</a>
									  <a class="dropdown-item" href="javascript:;">Another action</a>
									  <a class="dropdown-item" href="javascript:;">Something else here</a>
									</div>
								  </div>
								</div>
							  </div>
							  <p class="text-sm mt-3"> If everything I did failed - which it doesn't, I think that it actually succeeds. </p>
							  <hr class="horizontal dark">
							  <div class="row">
								<div class="col-6">
								  <h6 class="text-sm mb-0">5</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Participants</p>
								</div>
								<div class="col-6 text-end">
								  <h6 class="text-sm mb-0">02.03.22</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Due date</p>
								</div>
							  </div>
							</div>
						  </div>
						</div>
						<div class="col-lg-4 col-md-6 mb-4 mt-2 mt-md-0">
						  <div class="card">
							<div class="card-body p-3">
							  <div class="d-flex mt-n2">
								<div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
								  <img src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg" alt="spotify_logo">
								</div>
								<div class="ms-3 my-auto">
								  <h6 class="mb-0">Premium support</h6>
								</div>
								<div class="ms-auto">
								  <div class="dropdown">
									<button class="btn btn-link text-secondary ps-0 pe-2" id="navbarDropdownMenuLink1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									  <i class="fa fa-ellipsis-v text-lg"></i>
									</button>
									<div class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3" aria-labelledby="navbarDropdownMenuLink1">
									  <a class="dropdown-item" href="javascript:;">Action</a>
									  <a class="dropdown-item" href="javascript:;">Another action</a>
									  <a class="dropdown-item" href="javascript:;">Something else here</a>
									</div>
								  </div>
								</div>
							  </div>
							  <p class="text-sm mt-3"> Pink is obviously a better color. Everyone’s born confident, and everything’s taken . </p>
							  <hr class="horizontal dark">
							  <div class="row">
								<div class="col-6">
								  <h6 class="text-sm mb-0">3</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Participants</p>
								</div>
								<div class="col-6 text-end">
								  <h6 class="text-sm mb-0">22.11.21</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Due date</p>
								</div>
							  </div>
							</div>
						  </div>
						</div>
						<div class="col-lg-4 col-md-6 mb-4 mt-2">
						  <div class="card">
							<div class="card-body p-3">
							  <div class="d-flex mt-n2">
								<div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
								  <img src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg" alt="xd_logo">
								</div>
								<div class="ms-3 my-auto">
								  <h6 class="mb-0">Design tools</h6>
								</div>
								<div class="ms-auto">
								  <div class="dropdown">
									<button class="btn btn-link text-secondary ps-0 pe-2" id="navbarDropdownMenuLink2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									  <i class="fa fa-ellipsis-v text-lg"></i>
									</button>
									<div class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3" aria-labelledby="navbarDropdownMenuLink2">
									  <a class="dropdown-item" href="javascript:;">Action</a>
									  <a class="dropdown-item" href="javascript:;">Another action</a>
									  <a class="dropdown-item" href="javascript:;">Something else here</a>
									</div>
								  </div>
								</div>
							  </div>
							  <p class="text-sm mt-3"> Constantly growing. We’re constantly making mistakes from which we learn and improve. </p>
							  <hr class="horizontal dark">
							  <div class="row">
								<div class="col-6">
								  <h6 class="text-sm mb-0">4</h6>
								  <p class="text-secondary text-sm mb-0">Participants</p>
								</div>
								<div class="col-6 text-end">
								  <h6 class="text-sm mb-0">06.03.20</h6>
								  <p class="text-secondary text-sm mb-0">Due date</p>
								</div>
							  </div>
							</div>
						  </div>
						</div>
						<div class="col-lg-4 col-md-6 mb-4 mt-2">
						  <div class="card">
							<div class="card-body p-3">
							  <div class="d-flex mt-n2">
								<div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
								  <img src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg" alt="invision_logo">
								</div>
								<div class="ms-3 my-auto">
								  <h6 class="mb-0">Developer First</h6>
								</div>
								<div class="ms-auto">
								  <div class="dropdown">
									<button class="btn btn-link text-secondary ps-0 pe-2" id="navbarDropdownMenuLink4" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									  <i class="fa fa-ellipsis-v text-lg"></i>
									</button>
									<div class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3" aria-labelledby="navbarDropdownMenuLink4">
									  <a class="dropdown-item" href="javascript:;">Action</a>
									  <a class="dropdown-item" href="javascript:;">Another action</a>
									  <a class="dropdown-item" href="javascript:;">Something else here</a>
									</div>
								  </div>
								</div>
							  </div>
							  <p class="text-sm mt-3"> For standing out. But the time is now to be okay to be the greatest you. </p>
							  <hr class="horizontal dark">
							  <div class="row">
								<div class="col-6">
								  <h6 class="text-sm mb-0">4</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Participants</p>
								</div>
								<div class="col-6 text-end">
								  <h6 class="text-sm mb-0">16.01.22</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Due date</p>
								</div>
							  </div>
							</div>
						  </div>
						</div>
						<div class="col-lg-4 col-md-6 mb-4 mt-2">
							<div class="card">
							  <div class="card-body p-3">
								<div class="d-flex mt-n2">
								  <div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
									<img src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg" alt="invision_logo">
								  </div>
								  <div class="ms-3 my-auto">
									<h6 class="mb-0">Developer First</h6>
								  </div>
								  <div class="ms-auto">
									<div class="dropdown">
									  <button class="btn btn-link text-secondary ps-0 pe-2" id="navbarDropdownMenuLink4" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<i class="fa fa-ellipsis-v text-lg"></i>
									  </button>
									  <div class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3" aria-labelledby="navbarDropdownMenuLink4">
										<a class="dropdown-item" href="javascript:;">Action</a>
										<a class="dropdown-item" href="javascript:;">Another action</a>
										<a class="dropdown-item" href="javascript:;">Something else here</a>
									  </div>
									</div>
								  </div>
								</div>
								<p class="text-sm mt-3"> For standing out. But the time is now to be okay to be the greatest you. </p>
								<hr class="horizontal dark">
								<div class="row">
								  <div class="col-6">
									<h6 class="text-sm mb-0">4</h6>
									<p class="text-secondary text-sm font-weight-normal mb-0">Participants</p>
								  </div>
								  <div class="col-6 text-end">
									<h6 class="text-sm mb-0">16.01.22</h6>
									<p class="text-secondary text-sm font-weight-normal mb-0">Due date</p>
								  </div>
								</div>
							  </div>
							</div>
						  </div>
						<div class="col-lg-4 col-md-6 mb-4 mt-2">
						  <div class="card">
							<div class="card-body p-3">
							  <div class="d-flex mt-n2">
								<div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
								  <img src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg" alt="atlassian_logo">
								</div>
								<div class="ms-3 my-auto">
								  <h6 class="mb-0">Product Development</h6>
								</div>
								<div class="ms-auto">
								  <div class="dropdown">
									<button class="btn btn-link text-secondary ps-0 pe-2" id="navbarDropdownMenuLink4" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									  <i class="fa fa-ellipsis-v text-lg"></i>
									</button>
									<div class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3" aria-labelledby="navbarDropdownMenuLink4">
									  <a class="dropdown-item" href="javascript:;">Action</a>
									  <a class="dropdown-item" href="javascript:;">Another action</a>
									  <a class="dropdown-item" href="javascript:;">Something else here</a>
									</div>
								  </div>
								</div>
							  </div>
							  <p class="text-sm mt-3"> We strive to embrace and drive change in our industry. We are happy to work at such a project. </p>
							  <hr class="horizontal dark">
							  <div class="row">
								<div class="col-6">
								  <h6 class="text-sm mb-0">4</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Participants</p>
								</div>
								<div class="col-6 text-end">
								  <h6 class="text-sm mb-0">16.01.22</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Due date</p>
								</div>
							  </div>
							</div>
						  </div>
						</div>
					  </div>
  				</div>
				  <div class="white-bg">
					<div class="row mt-lg-4 mt-2">
						<h3>My Requests</h3>
						<div class="col-lg-4 col-md-6 mb-4">
						  <div class="card">
							<div class="card-body p-3">
							  <div class="d-flex mt-n2">
								<div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
								  <img src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg" alt="slack_logo">
								</div>
								<div class="ms-3 my-auto">
								  <h6 class="mb-0">Slack Bot</h6>
								</div>
								<div class="ms-auto">
								  <div class="dropdown">
									<button class="btn btn-link text-secondary ps-0 pe-2" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									  <i class="fa fa-ellipsis-v text-lg"></i>
									</button>
									<div class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3" aria-labelledby="navbarDropdownMenuLink">
									  <a class="dropdown-item" href="javascript:;">Action</a>
									  <a class="dropdown-item" href="javascript:;">Another action</a>
									  <a class="dropdown-item" href="javascript:;">Something else here</a>
									</div>
								  </div>
								</div>
							  </div>
							  <p class="text-sm mt-3"> If everything I did failed - which it doesn't, I think that it actually succeeds. </p>
							  <hr class="horizontal dark">
							  <div class="row">
								<div class="col-6">
								  <h6 class="text-sm mb-0">5</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Participants</p>
								</div>
								<div class="col-6 text-end">
								  <h6 class="text-sm mb-0">02.03.22</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Due date</p>
								</div>
							  </div>
							</div>
						  </div>
						</div>
						<div class="col-lg-4 col-md-6 mb-4 mt-2">
						  <div class="card">
							<div class="card-body p-3">
							  <div class="d-flex mt-n2">
								<div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
								  <img src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg" alt="asana_logo">
								</div>
								<div class="ms-3 my-auto">
								  <h6 class="mb-0">Looking great</h6>
								</div>
								<div class="ms-auto">
								  <div class="dropdown">
									<button class="btn btn-link text-secondary ps-0 pe-2" id="navbarDropdownMenuLink3" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									  <i class="fa fa-ellipsis-v text-lg"></i>
									</button>
									<div class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3" aria-labelledby="navbarDropdownMenuLink3">
									  <a class="dropdown-item" href="javascript:;">Action</a>
									  <a class="dropdown-item" href="javascript:;">Another action</a>
									  <a class="dropdown-item" href="javascript:;">Something else here</a>
									</div>
								  </div>
								</div>
							  </div>
							  <p class="text-sm mt-3"> You have the opportunity to play this game of life you need to appreciate every moment. </p>
							  <hr class="horizontal dark">
							  <div class="row">
								<div class="col-6">
								  <h6 class="text-sm mb-0">6</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Participants</p>
								</div>
								<div class="col-6 text-end">
								  <h6 class="text-sm mb-0">14.03.24</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Due date</p>
								</div>
							  </div>
							</div>
						  </div>
						</div>
						<div class="col-lg-4 col-md-6 mb-4 mt-2">
						  <div class="card">
							<div class="card-body p-3">
							  <div class="d-flex mt-n2">
								<div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
								  <img src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg" alt="atlassian_logo">
								</div>
								<div class="ms-3 my-auto">
								  <h6 class="mb-0">Product Development</h6>
								</div>
								<div class="ms-auto">
								  <div class="dropdown">
									<button class="btn btn-link text-secondary ps-0 pe-2" id="navbarDropdownMenuLink4" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									  <i class="fa fa-ellipsis-v text-lg"></i>
									</button>
									<div class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3" aria-labelledby="navbarDropdownMenuLink4">
									  <a class="dropdown-item" href="javascript:;">Action</a>
									  <a class="dropdown-item" href="javascript:;">Another action</a>
									  <a class="dropdown-item" href="javascript:;">Something else here</a>
									</div>
								  </div>
								</div>
							  </div>
							  <p class="text-sm mt-3"> We strive to embrace and drive change in our industry. We are happy to work at such a project. </p>
							  <hr class="horizontal dark">
							  <div class="row">
								<div class="col-6">
								  <h6 class="text-sm mb-0">4</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Participants</p>
								</div>
								<div class="col-6 text-end">
								  <h6 class="text-sm mb-0">16.01.22</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Due date</p>
								</div>
							  </div>
							</div>
						  </div>
						</div>
					  </div>
  				</div>
			</div>
	  	</div>
	</section>
</main>