<main *ngIf="detailedList">
	<section>
	  	<div class="rad-body-wrapper">
			<div class="container-fluid">
				<div class="white-bg">
		  			<div class="row">
						<div class="col-lg-6 col-md-6 col-xs-12">
			  				<div class="dash-profile">
								<div class="avatar-upload">
		  							<div class="avatar-edit">
			  							<label for="imageUpload" (click)="openPictureAdd(addPictureContent)">
											<svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none"><circle cx="15.5249" cy="15" r="15" fill="#FF0000"/><path d="M10.2925 17.2687V14.1757H21.4553V17.2687H10.2925ZM14.3255 9.76758H17.4463V21.628H14.3255V9.76758Z" fill="white"/></svg>
										</label>
		  							</div>
		  							<div class="avatar-preview">
			  							<div id="imagePreview">
											<img src="{{detailedList.details.profile_picture}}" *ngIf="detailedList.details.profile_picture" alt="profile_image" class="w-100 border-radius-lg shadow-sm">
											<img src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg" *ngIf="!detailedList.details.profile_picture" alt="profile_image" class="w-100 border-radius-lg shadow-sm">
										</div>
		  							</div>
	  							</div>
								<div class="text-dash">
				  					<h2>{{detailedList.details.name}}</h2>
				  					<p>{{type}}</p>
								</div>
			  				</div>
						</div>
						<div class="col-lg-6 col-md-6 col-xs-12">
			  				<p class="text-right"><strong>Join Date:</strong> {{detailedList.details.created | date :'MMM, y'}}</p>
			  				<p class="text-right text-left-mob"><a style="color: #ff0000;" target="_blank" href="public_profile/{{map_id}}">View Public Profile</a></p>
						</div>
		 	 		</div>
		  			<div class="row">
						<div class="col-md-12">
			 				<div class="bio-prof">
			  					<h3>Your Bio 
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" (click)="openBioAdd(addBioContent)">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.44772 0.44772 0 1 0H10V2H2V18H18V10H20V19C20 19.5523 19.5523 20 19 20H1C0.44772 20 0 19.5523 0 19V1Z" fill="#FF0000"/>
										<path d="M19.9999 2.92683L17.0731 0L15.146 1.92704L18.0729 4.85387L19.9999 2.92683Z" fill="#FF0000"/>
										<path d="M8 12.0001L11.2195 11.7074L16.6587 6.26814L13.7319 3.34131L8.2927 8.78056L8 12.0001Z" fill="#FF0000"/>
  									</svg>
								</h3>
			  					<p>{{detailedList.details.about}}</p>
			 				</div>
						</div>
		  			</div>
					<div class="row">
			  			<div class="col-lg-6 col-md-6 col-xs-12">
							<div class="business-info">
								<h3>Business Information 
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" (click)="openBusinessAdd(addBusinessContent)">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.44772 0.44772 0 1 0H10V2H2V18H18V10H20V19C20 19.5523 19.5523 20 19 20H1C0.44772 20 0 19.5523 0 19V1Z" fill="#FF0000"/>
										<path d="M19.9999 2.92683L17.0731 0L15.146 1.92704L18.0729 4.85387L19.9999 2.92683Z" fill="#FF0000"/>
										<path d="M8 12.0001L11.2195 11.7074L16.6587 6.26814L13.7319 3.34131L8.2927 8.78056L8 12.0001Z" fill="#FF0000"/>
									</svg>
								</h3>
								<p><strong>Phone Number: </strong> {{detailedList.details.phone}}</p>
								<p><strong>E-mail: </strong> {{detailedList.details.email}}</p>
								<p><strong>Emergency Number: </strong> {{detailedList.details.e_phone}}</p>
								<p><strong>Address: </strong> {{detailedList.details.address}}</p>
							</div>
			  			</div>
						<div class="col-lg-6 col-md-6 col-xs-12">
					 		<div class="business-info">
				   				<h3>Contact Person Details
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" (click)="openContactAdd(addContactContent)">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.44772 0.44772 0 1 0H10V2H2V18H18V10H20V19C20 19.5523 19.5523 20 19 20H1C0.44772 20 0 19.5523 0 19V1Z" fill="#FF0000"/>
										<path d="M19.9999 2.92683L17.0731 0L15.146 1.92704L18.0729 4.85387L19.9999 2.92683Z" fill="#FF0000"/>
										<path d="M8 12.0001L11.2195 11.7074L16.6587 6.26814L13.7319 3.34131L8.2927 8.78056L8 12.0001Z" fill="#FF0000"/>
  									</svg>
								</h3>
								<p><strong>Name: </strong> {{detailedList.details.c_name}}</p>
								<p><strong>Phone Number: </strong> {{detailedList.details.c_phone}}</p>
								<p><strong>E-mail: </strong> {{detailedList.details.c_email}}</p>
							</div>
						</div>
					</div>
  					<div class="list-grop-button">
						<h3>Application</h3>
  						<ul class="list-group">
	 						<li class="list-group-item border-0 px-0">
								<div class="form-check form-switch ps-0 is-filled">
									<input class="form-check-input ms-auto" type="checkbox" id="flexSwitchCheckDefault4" [checked] = "detailedList.details.make_public" (change)="makeYourself($event)">
									<label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault4">Make yourself public</label>
								</div>
							</li>
							<li class="list-group-item border-0 px-0">
								<div class="form-check form-switch ps-0 is-filled">
									<input class="form-check-input ms-auto" type="checkbox" id="flexSwitchCheckDefault3" [checked] = "detailedList.details.newsletter"  (change)="newsletter($event)">
									<label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault3">Recieve a newslater</label>
								</div>
							</li>			 
							<li class="list-group-item border-0 px-0 pb-0">
								<div class="form-check form-switch ps-0 is-filled">
									<input class="form-check-input ms-auto" type="checkbox" id="flexSwitchCheckDefault5" [checked] = "detailedList.details.latest_update" (change)="latestUpdate($event)">
									<label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault5">Latest update on donorzilla</label>
								</div>
							</li>
						</ul>
					</div>
					<div class="list-grop-button">
						<h3>Social Media
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" (click)="openSocialMediaAdd(addSocialMediaContent)">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.44772 0.44772 0 1 0H10V2H2V18H18V10H20V19C20 19.5523 19.5523 20 19 20H1C0.44772 20 0 19.5523 0 19V1Z" fill="#FF0000"/>
								<path d="M19.9999 2.92683L17.0731 0L15.146 1.92704L18.0729 4.85387L19.9999 2.92683Z" fill="#FF0000"/>
								<path d="M8 12.0001L11.2195 11.7074L16.6587 6.26814L13.7319 3.34131L8.2927 8.78056L8 12.0001Z" fill="#FF0000"/>
							</svg>
						</h3>
  						<ul class="list-group icon-width">
	 						<li class="list-group-item border-0 px-0">
								<a href="{{detailedList.details.facebook_link}}" target="_blank" title="Facebook"><i class="fa fa-facebook-f"></i></a>
							</li>
							<li class="list-group-item border-0 px-0">
								<a href="{{detailedList.details.twitter_link}}" target="_blank" title="Twitter"><i class="fa fa-twitter"></i></a>
							</li>
							<li class="list-group-item border-0 px-0">
								<a href="{{detailedList.details.instagram_link}}" target="_blank" title="Instagram"><i class="fa fa-instagram"></i></a>
							</li>
							<li class="list-group-item border-0 px-0">
								<a href="{{detailedList.details.youtube_link}}" target="_blank" title="Youtube"><i class="fa fa-youtube"></i></a>
							</li>
							<li class="list-group-item border-0 px-0">
								<a href="{{detailedList.details.linkedin_link}}" title="Linkedin" target="_blank"><i class="fa fa-linkedin"></i></a>
							</li>
							<li class="list-group-item border-0 px-0">
								<a href="{{detailedList.details.website_link}}" title="Linkedin" target="_blank"><i class="fa fa-globe"></i></a>
							</li>
						</ul>
					</div>
				</div>
				<div class="white-bg">
  					<div class="map-dash">
						<iframe [src]="transform()" width="100%" height="150" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  					</div>
  				</div>  
				<div class="white-bg">
  					<div class="serv-dep">
						<h3>Department</h3>
						<div class="img-txt-dep">
							<p (click)="openDepartmentAdd(addDepartmentContent)"><img src="https://resources.donorzilla.com/statics/images/1p.svg"> Add here</p>
							<p *ngFor="let departmentMap of departmentMapList;let i = index"><img src="{{departmentMap.image}}"> {{departmentMap.name}}</p>
						</div>  
  					</div>
  				</div>
				<div class="white-bg">
					<div class="serv-dep">
						<h3>Service and Amenities</h3>
						<div class="img-txt-dep">
							<div class="row">
								<div class="ameniti">
								  	<div class="col-lg-1 col-md-2 col-sm-3 col-4">
										<p (click)="openAmenitiesAdd(addAmenitiesContent)"><img src="https://resources.donorzilla.com/statics/images/1p.svg"> Add here</p>
									</div>
									<div class="col-lg-1 col-md-2 col-sm-3 col-4" *ngFor="let amenityMap of amenityMapList;let i = index">
										<p><img src="{{amenityMap.image}}"> {{amenityMap.name}}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
  				</div>
			</div>
	  	</div>
	</section>
</main>  
<ng-template #addPictureContent let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="fw-bold">Update Profile Picture</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
			<i class="ki-duotone ki-cross fs-1">
				<span class="path1"></span>
				<span class="path2"></span>
			</i>
		</div>
	</div>
	<div class="modal-body scroll-y mx-xl-15">
		<div class="login-container">
			<div class="login-form">
				<div class="login-form-inner" style="max-width: 100%;">
					<form [formGroup]="addPictureForm" (ngSubmit)="addPicture($event)">
						<div class="login-form-group">
							<img src="{{currentImage}}" height="60" /><br><br>
							<input #imageInput class="form-control form-control-solid" type="file" accept="image/*" (change)="processFile(imageInput)" />
						</div>
						<button type="submit" class="btn bg-gradient-primary wd-25" style="margin-right: 10px;">Update</button>
						<button type="button" (click)="modal.dismiss('Cross click')" class="btn bg-gradient-primary wd-25">Cancel</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #addBioContent let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="mx-5">Update Bio</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
			<i class="ki-duotone ki-cross fs-1">
				<span class="path1"></span>
				<span class="path2"></span>
			</i>
		</div>
	</div>
	<div class="modal-body scroll-y mx-xl-15">
		<div class="login-container">
			<div class="login-form">
				<div class="login-form-inner" style="max-width: 100%;">
					<form [formGroup]="addBioForm" (ngSubmit)="addBio($event)">
						<div class="login-form-group">
							<label for="about" class="label-width">About <span class="required-star">*</span></label>
							<textarea rows="7" class="form-input textarea-box" formControlName="about" autocomplete="off"></textarea>
						</div>
						<button type="submit" class="btn bg-gradient-primary wd-25 submit-btn-gradient">Update</button>
						<button type="button" (click)="modal.dismiss('Cross click')" class="btn bg-gradient-primary wd-25 cancel-btn-modal">Cancel</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #addBusinessContent let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="mx-5">Business Information</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
			<i class="ki-duotone ki-cross fs-1">
				<span class="path1"></span>
				<span class="path2"></span>
			</i>
		</div>
	</div>
	<div class="modal-body scroll-y mx-xl-15">
		<div class="login-container">
			<div class="login-form">
				<div class="login-form-inner" style="max-width: 100%;">
					<form [formGroup]="addBusinessForm" (ngSubmit)="addBusiness($event)">
						<div class="login-form-group">
							<label for="email" class="label-width">Email <span class="required-star">*</span></label>
							<input type="text" class="input-modal" placeholder="Email" formControlName="email" autocomplete="off">
						</div>
						<div class="login-form-group">
							<label for="e_phone" class="label-width">Emergency Phone Number <span class="required-star">*</span></label>
							<input type="text" class="input-modal" placeholder="Emergency Phone Number" formControlName="e_phone" autocomplete="off">
						</div>
						<div class="login-form-group">
							<label for="address" class="label-width">Address <span class="required-star">*</span></label>
							<input type="text" class="input-modal" placeholder="Address" formControlName="address" autocomplete="off">
						</div>
						<div *ngIf="successMessage">
							<div class="alert alert-success" role="alert">
								{{successMessage}}
							</div>
						</div>
						<div *ngIf="errorMessage">
							<div class="alert alert-danger" role="alert">
								{{errorMessage}}
							</div>
						</div>
						<button type="submit" class="btn bg-gradient-primary wd-25 submit-btn-gradient">Update</button>
						<button type="button" (click)="modal.dismiss('Cross click')" class="btn bg-gradient-primary wd-25 cancel-btn-modal">Cancel</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #addContactContent let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="mx-5">Contact Details</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
			<i class="ki-duotone ki-cross fs-1">
				<span class="path1"></span>
				<span class="path2"></span>
			</i>
		</div>
	</div>
	<div class="modal-body scroll-y mx-xl-15">
		<div class="login-container">
			<div class="login-form">
				<div class="" style="width: 100%;">
					<form [formGroup]="addContactForm" (ngSubmit)="addContact($event)">
						<div class="login-form-group">
							<label for="c_name">Name <span class="required-star">*</span></label>
							<input type="text" class="input-modal" placeholder="Name" formControlName="c_name" autocomplete="off">
						</div>
						<div class="login-form-group">
							<label for="c_phone">Phone Number <span class="required-star">*</span></label>
							<input type="text" class="input-modal" placeholder="Phone Number" formControlName="c_phone" autocomplete="off">
						</div>
						<div class="login-form-group">
							<label for="c_email">Email <span class="required-star">*</span></label>
							<input type="text" class="input-modal" placeholder="Email" formControlName="c_email" autocomplete="off">
						</div>
						<div *ngIf="successMessage">
							<div class="alert alert-success" role="alert">
								{{successMessage}}
							</div>
						</div>
						<div *ngIf="errorMessage">
							<div class="alert alert-danger" role="alert">
								{{errorMessage}}
							</div>
						</div>
						<button type="submit" class="btn bg-gradient-primary wd-25 submit-btn-gradient">Update</button>
						<button type="button" (click)="modal.dismiss('Cross click')" class="btn bg-gradient-primary wd-25 cancel-btn-modal">Cancel</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #addSocialMediaContent let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="mx-5">Social Media Details</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
			<i class="ki-duotone ki-cross fs-1">
				<span class="path1"></span>
				<span class="path2"></span>
			</i>
		</div>
	</div>
	<div class="modal-body scroll-y mx-xl-15">
		<div class="login-container">
			<div class="login-form">
				<div class="login-form-inner" style="max-width: 100%;">
					<form [formGroup]="addSocialMediaForm" (ngSubmit)="addSocialMedia($event)">
						<div class="login-form-group">
							<label for="c_name">Facebook</label>
							<input type="text" class="input-modal" placeholder="Facebook" formControlName="facebook_link">
						</div>
						<div class="login-form-group">
							<label for="c_phone">Twitter</label>
							<input type="text" class="input-modal" placeholder="Twitter" formControlName="twitter_link">
						</div>
						<div class="login-form-group">
							<label for="c_email">Instagram</label>
							<input type="text" class="input-modal" placeholder="Instagram" formControlName="instagram_link">
						</div>
						<div class="login-form-group">
							<label for="c_name">Youtube</label>
							<input type="text" class="input-modal" placeholder="Youtube" formControlName="youtube_link">
						</div>
						<div class="login-form-group">
							<label for="c_phone">LinkedIn</label>
							<input type="text" class="input-modal" placeholder="LinkedIn" formControlName="linkedin_link">
						</div>
						<div class="login-form-group">
							<label for="c_email">Website</label>
							<input type="text" class="input-modal" placeholder="Website" formControlName="website_link">
						</div>
						<div *ngIf="successMessage">
							<div class="alert alert-success" role="alert">
								{{successMessage}}
							</div>
						</div>
						<div *ngIf="errorMessage">
							<div class="alert alert-danger" role="alert">
								{{errorMessage}}
							</div>
						</div>
						<button type="submit" class="btn bg-gradient-primary wd-25 submit-btn-gradient">Update</button>
						<button type="button" (click)="modal.dismiss('Cross click')" class="btn bg-gradient-primary wd-25 cancel-btn-modal">Cancel</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #addAmenitiesContent let-modal>
	<div class="mdl-middle" *ngIf="amenityList">
		<form [formGroup]="addAmenityMapForm" (ngSubmit)="addAmenityMap($event)">
			<h2>Amenities List</h2>
			<div class="amenity-inner">
				<a href="javascript:;" *ngFor="let amenity of amenityList;let i = index" [class.flag]="someMethod(amenity.id)" (click)="onAmenityChange(amenity.id)">
					<img src="{{amenity.image}}">
					<p>{{amenity.name}}</p>
				</a>
			</div>
			<button type="submit" class="btn bg-gradient-primary wd-25 submit-btn-gradient">Update</button>
			<button type="button" (click)="modal.dismiss('Cross click')" class="btn bg-gradient-primary wd-25 cancel-btn-modal" id="amenityModalClose">Cancel</button>
		</form>
	</div>
</ng-template>
<ng-template #addDepartmentContent let-modal>
	<div class="mdl-middle">
		<form [formGroup]="addDepartmentMapForm" (ngSubmit)="addDepartmentMap($event)">
			<h2>Department List</h2>
			<!-- <div class="searchInput">
				<input type="text" placeholder="Search">
				<div class="resultBox"></div>
				<div class="icon"><i class="fas fa-search"></i></div>
		  	</div> -->
			<div class="field">
				<div class="check-text-inner" *ngFor="let department of departmentList;let i = index">
					<img src="{{department.image}}" alt="{{department.name}}" class="w-10">
					<h3>{{department.name}}</h3>
			 		<p>{{department.description}}</p> 
			 		<input type="checkbox" value="{{department.id}}" (change)="onDepartmentChange($event)"> 
		   		</div>
			</div>
			<button type="submit" class="btn bg-gradient-primary wd-25 submit-btn-gradient">Update</button>
			<button type="button" (click)="modal.dismiss('Cross click')" class="btn bg-gradient-primary wd-25 cancel-btn-modal" id="departmentModalClose">Cancel</button>
		</form>
	</div>
</ng-template>